import * as React from "react"

// styles
import "./index.css"

// Img
import Logo from "../img/coming-soon-logo-two.svg";
import Bg from "../img/bg-pot.png"

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Kitchen Foood | Coming Soon</title>
      <div className="img-container">
        <img src={Logo} alt="Logo" className="logo"/>
        <img src={Bg} className="bg" />
        <div className="color-overlay"></div>
      </div>
    </main>
  )
}

export default IndexPage
